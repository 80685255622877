import { FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import {
	DataGrid,
	GridCellParams,
	GridPaginationModel,
	GridSortDirection,
	GridSortModel,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid';
import type { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
// import { ReactComponent as Cancel } from 'assets/icons/cancel_remove.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import dayjs from 'dayjs';
import { ROUTES } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
	getOtherReceiptsBillCancel,
	getOtherReceiptsList,
	getOtherReceiptsListIsLoadingSelector,
	getOtherReceiptsListSelector,
	getOtherReceiptsReport,
} from 'store/other-receipts';
import { IOtherReceiptsFilters, IQuery } from 'types';
import { CustomDatePicker, MemoizedCustomCell } from 'components/elements';
import toastService from 'components/elements/Toastify';
import { PAGINATION_LIMIT } from 'utils/constants';
import { DATE_FORMAT, OTHER_RECEIPTS_FIELDS_NAME, PAYMENT_STATUS, SORT_DIR } from 'utils/enums';
import {
	convertAndSumCoinsToUAH,
	convertCoinsToUAH,
	formatQueryString,
	getLocaleAmountString,
	getStatusTranslation,
	parseQueryString,
} from 'utils/helpers';
import { getTableSetting } from 'utils/helpers/table-setting';
import { ServicesService } from 'utils/services';
import { COLUMNS_CONFIG, disablePastYear, downloadReportPdf, getRowHeight, shouldDisableMonth } from './config';
import styles from './index.module.scss';

export const OtherReceiptsPage: FC = () => {
	const { search } = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const query = useMemo(() => parseQueryString<IQuery>(search, { parseBooleans: true, parseNumbers: true }), [search]);
	const apiRef = useGridApiRef();
	const [columnWidths, setColumnWidths] = useState({});

	const otherReceiptsList = useAppSelector(getOtherReceiptsListSelector);
	const isLoading = useAppSelector(getOtherReceiptsListIsLoadingSelector);

	const [options, setOptions] = useState<string[]>([]);
	const [filters, setFilters] = useState<IOtherReceiptsFilters>({
		providerService: query.providerService || '',
		dateRange: {
			gte: query.gte || undefined,
			lte: query.lte || undefined,
		},
	});

	const requestBody = useMemo(() => {
		const baseRequestBody = {
			pagination: {
				offset: (query.page || 0) * (query.pageSize || 0),
				limit: query.pageSize || PAGINATION_LIMIT.SMALL,
			},
			sorting: {
				field: query.field || OTHER_RECEIPTS_FIELDS_NAME.PAID_AT,
				direction: query.sort || SORT_DIR.DSC,
			},
			search: query.searchQuery || undefined,
		};

		const filteredRequestBody = {
			...baseRequestBody,
			...(filters.providerService || (filters.dateRange?.gte && filters.dateRange?.lte)
				? {
						providerService: filters.providerService,
						dateRange: {
							gte: filters.dateRange?.gte,
							lte: filters.dateRange?.lte,
						},
				  }
				: {}),
		};

		return filteredRequestBody;
	}, [query]);

	useEffect(() => {
		dispatch(getOtherReceiptsList(requestBody));
	}, [requestBody, dispatch]);

	const handleSearchService = async (value: string) => {
		const servicesRequestBody = {
			...requestBody,
			sorting: {
				field: 'name',
				direction: SORT_DIR.ASC,
			},
			search: value || '',
		};
		const { data } = await ServicesService.getServices(servicesRequestBody);
		setOptions(data.results.map((service) => service.name));
	};

	const handleChangePage = ({ page, pageSize }: GridPaginationModel): void => {
		navigate(
			{
				search: formatQueryString({
					...query,
					page,
					pageSize,
				}),
			},
			{ replace: true }
		);
	};

	const handleSortField = (model: GridSortModel): void => {
		const { field, sort } = model[0] || {};
		navigate(
			{
				search: formatQueryString({
					...query,
					field,
					sort,
				}),
			},
			{ replace: true }
		);
	};

	const handleColumnResize = (params: any) => {
		const newWidths = {
			...columnWidths,
			[params.colDef.field]: params.width,
		};
		setColumnWidths(newWidths);
		localStorage.setItem(ROUTES.otherReceipts, JSON.stringify(newWidths));
	};

	useEffect(() => {
		setColumnWidths(getTableSetting(ROUTES.otherReceipts));
	}, [requestBody]);

	COLUMNS_CONFIG.forEach((item: GridColDef) => {
		// @ts-ignore
		if (columnWidths && columnWidths[item.field]) {
			// @ts-ignore
			item.width = columnWidths[item.field];
			item.flex = undefined;
		}
	});

	const modifiedOtherReceipts = useMemo(
		() =>
			otherReceiptsList?.results.map((otherReceipts, index) => ({
				...otherReceipts,
				id: index,
				providerBillId: otherReceipts.providerBillId ?? '',
				billId: otherReceipts.billId ?? '',
				amount: getLocaleAmountString(convertCoinsToUAH(otherReceipts.amount)),
				fee: getLocaleAmountString(convertCoinsToUAH(otherReceipts.fee)),
				paidAmount: getLocaleAmountString(convertAndSumCoinsToUAH(otherReceipts.amount, otherReceipts.fee)),
				status: getStatusTranslation(otherReceipts.status),
				paidAt: dayjs.utc(otherReceipts.paidAt).format(DATE_FORMAT.DATE_FULL),
			})),
		[otherReceiptsList]
	);

	const handleDateChange = (date: Date | null, name: string) => {
		let formattedDate: string | null = null;

		if (date) {
			const dayjsDate = dayjs(date);
			formattedDate = name === 'gte' ? dayjsDate.startOf('day').format() : dayjsDate.endOf('day').format();
		}
		setFilters({
			...filters,
			dateRange: {
				...filters.dateRange,
				[name]: formattedDate,
			},
		});
	};

	const handleAutocompleteChange = (fieldName: string, value: any): void => {
		setFilters({ ...filters, providerService: value });
	};

	const handleClickOnCell = async (params: GridCellParams, event: MuiEvent<MouseEvent>) => {
		if (params.field === 'download') {
			event.preventDefault();
			try {
				const report = await dispatch(getOtherReceiptsReport(params.row.billId)).unwrap();
				downloadReportPdf(report);
			} catch (error) {
				toastService.error('Failed to download the report');
			}
			return;
		}

		if (params.field === 'cancel') {
			event.preventDefault();
			const isToday = dayjs(params.row.paidAt).isSame(dayjs(), 'day');
			const isPaid = params.row.status === PAYMENT_STATUS.PAID;

			if (isToday && isPaid) {
				try {
					await dispatch(getOtherReceiptsBillCancel(params.row.billId)).unwrap();
					toastService.success('Платіж повернуто');
				} catch (error) {
					toastService.error('Failed to cancel the payment');
				}
			} else {
				toastService.warning('Неможливо повернути платіж');
			}
		}
	};

	const mergedColumnsConfig = COLUMNS_CONFIG.map((column) => {
		if (column.field === OTHER_RECEIPTS_FIELDS_NAME.REQ_DATA) {
			return {
				...column,
				renderCell: (params: any) => <MemoizedCustomCell reqData={JSON.parse(params.value)} />,
				sortable: false,
			};
		}
		return column;
	});

	const handleClickFiltersButton = () => {
		navigate(
			{
				search: formatQueryString({
					...query,
					providerService: filters.providerService,
					gte: filters.dateRange?.gte,
					lte: filters.dateRange?.lte,
				}),
			},
			{ replace: true }
		);
	};

	return (
		<>
			<Box className={styles.tableActions}>
				<Box className={styles.tableActions__items}>
					<Box className={styles.date__filters}>
						<CustomDatePicker
							name="gte"
							views={['year', 'month', 'day']}
							format={DATE_FORMAT.DAY_MONTH_NAME_YEAR}
							shouldDisableMonth={shouldDisableMonth}
							shouldDisableYear={disablePastYear}
							className={styles.date__input}
							onChange={(date) => handleDateChange(date, 'gte')}
						/>
						<span>-</span>
						<CustomDatePicker
							name="lte"
							views={['year', 'month', 'day']}
							format={DATE_FORMAT.DAY_MONTH_NAME_YEAR}
							shouldDisableMonth={shouldDisableMonth}
							shouldDisableYear={disablePastYear}
							className={styles.date__input}
							onChange={(date) => handleDateChange(date, 'lte')}
						/>
					</Box>
					<Autocomplete
						options={options || []}
						noOptionsText="Не знайдено"
						renderInput={(params) => <TextField label="Сервіси" {...params} />}
						getOptionLabel={(option: string) => option}
						onInputChange={(_, value) => handleSearchService(value)}
						onChange={(_, value) => handleAutocompleteChange('providerService', value)}
						sx={{
							'& .MuiOutlinedInput-root': {
								padding: 0,
								minWidth: '260px',
							},
						}}
						value={filters.providerService || ''}
					/>

					<Button
						variant="contained"
						disabled={!(filters.providerService || (filters.dateRange?.gte && filters.dateRange?.lte))}
						onClick={handleClickFiltersButton}
					>
						Застосувати
					</Button>
				</Box>
			</Box>
			<Box className={styles.tableWrapper}>
				<DataGrid
					onColumnResize={handleColumnResize}
					apiRef={apiRef}
					disableColumnFilter
					disableRowSelectionOnClick
					rows={modifiedOtherReceipts || []}
					rowCount={otherReceiptsList?.total || 0}
					loading={isLoading}
					getRowHeight={getRowHeight}
					onCellClick={(params, event) => handleClickOnCell(params, event)}
					columns={[
						...mergedColumnsConfig,
						// {
						// 	field: 'cancel',
						// 	headerName: 'Cкасування оплати',
						// 	sortable: false,
						// 	align: 'center',
						// 	width: 60,
						// 	headerAlign: 'center',
						// 	renderCell: () => (
						// 		<Tooltip title="Скасувати платіж" placement="top">
						// 			<Box style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						// 				<Cancel />
						// 			</Box>
						// 		</Tooltip>
						// 	),
						// },
						{
							field: 'download',
							headerName: ' ',
							sortable: false,
							align: 'center',
							width: 60,
							renderCell: () => <Download style={{ height: '100%' }} />,
						},
					]}
					initialState={{
						pagination: {
							paginationModel: { page: query.page || 0, pageSize: query.pageSize || PAGINATION_LIMIT.SMALL },
						},
						sorting: {
							sortModel: [
								{
									field: query.field || OTHER_RECEIPTS_FIELDS_NAME.CREATED_AT,
									sort: query.sort as GridSortDirection,
								},
							],
						},
					}}
					slotProps={{
						pagination: {
							labelRowsPerPage: 'Показувати :',
						},
					}}
					paginationMode="server"
					sortingMode="server"
					onSortModelChange={handleSortField}
					onPaginationModelChange={handleChangePage}
					className={styles.table}
					pageSizeOptions={[PAGINATION_LIMIT.SMALL, PAGINATION_LIMIT.MIDDLE, PAGINATION_LIMIT.BIG]}
				/>
			</Box>
		</>
	);
};
