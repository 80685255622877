import { IPaymentMethods, IPaymentMethodsSelectData } from 'types';
import { TOGGLE_VARIANTS } from 'utils/enums';

export const generatePaymentMethodsSelectOptions = (paymentMethods: IPaymentMethods): IPaymentMethodsSelectData =>
	Object.entries(paymentMethods).reduce(
		(acc, [key, value]) => ({ ...acc, [key]: value ? TOGGLE_VARIANTS.TRUE : TOGGLE_VARIANTS.FALSE }),
		{} as IPaymentMethodsSelectData
	);

export const formatPaymentMehodsFromSelect = (paymentMethodsSelectData: IPaymentMethodsSelectData) =>
	Object.entries(paymentMethodsSelectData).reduce(
		(acc, [key, value]) => ({ ...acc, [key]: value === TOGGLE_VARIANTS.TRUE }),
		{} as IPaymentMethods
	);
