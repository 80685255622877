import { ChangeEvent, useMemo, useState } from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	DialogProps,
	DialogTitle,
	IconButton,
	SelectChangeEvent,
	TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useAppSelector } from 'store/hooks';
import { getRightsSelector } from 'store/rights-list';
import { getRolesSelector } from 'store/roles-list';
import { IDetailsSetItem } from 'types';
import { CustomSelect } from 'components/elements/CustomSelect';
import { SelectOptions, TOGGLE_SELECT_OPTIONS, USER_ROLE_OPTIONS,USER_STATUS_OPTIONS } from 'utils/constants';
import { EDIT_MODAL_FIELDS, SERVICE_DETAILS_FEE_KEYS, SERVICE_DETAILS_PAYMENT_METHODS_KEYS } from 'utils/enums';
import { AsyncAutocomplete } from '../AsyncAutocomplete';

interface EditModalProps<T> extends Omit<DialogProps, 'open' | 'onClose'> {
	title: string;
	isOpen: boolean;
	onClose: () => void;
	selectedItem: IDetailsSetItem<T>;
	handleSave: (value: string, additionalData?: unknown) => void;
	options?: SelectOptions<T>
	defaultValue: string;
}

export const EditModal = <T,>({
	isOpen,
	onClose,
	title,
	children,
	selectedItem,
	handleSave,
	defaultValue,
	...props
}: EditModalProps<T>) => {
	const [inputValue, setInputValue] = useState(selectedItem.value);
	const [validationFee, setValidationFee] = useState<boolean>(true);
	const [additionalData, setAdditionalData] = useState<unknown | null>(null);

	const rights = useAppSelector(getRightsSelector);
	const rightsOptions = useMemo(()=>{
		return rights?.tableData.map(({ name, description })=>({
			value: name,
			title: description,
		}));
	},  [rights]);

	const roles = useAppSelector(getRolesSelector);
	const rolesOptions = useMemo(()=>{
		return roles?.tableData.map(({ name, id })=>({
			value: id,
			title: name,
		}));
	},  [roles]);

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
	};

	const handleOnChangeFee = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;

		if (selectedItem.key === SERVICE_DETAILS_FEE_KEYS.FEE_PERCENT) {
			if (Number(value) < -100 || Number(value) > 100) {
				setInputValue('')
				return;
			}
		}

		if (selectedItem.key !== SERVICE_DETAILS_FEE_KEYS.FIXED_AMOUNT && selectedItem.key !== SERVICE_DETAILS_FEE_KEYS.FEE_PERCENT) {
			if (parseFloat(value) <= 0) {
				setInputValue('0');
				return;
			}
		}

		if(value.split('.')[1]){
			if (value.split('.')[1].length > 2) {
				return;
			}
		}

		if (selectedItem.additionalValue && parseFloat(selectedItem.additionalValue) !== 0) {
			if (selectedItem.limitValue) {
				switch (selectedItem.key) {
					case SERVICE_DETAILS_FEE_KEYS.FEE_MAX:
					case SERVICE_DETAILS_FEE_KEYS.SUM_TO: {
						if (parseFloat(value) <= parseFloat(selectedItem.additionalValue) && parseFloat(value) > parseFloat(selectedItem.limitValue)) {
							setValidationFee(false);
						} else {
							setValidationFee(true);
						}
						break;
					}
					case SERVICE_DETAILS_FEE_KEYS.FEE_MIN:
					case SERVICE_DETAILS_FEE_KEYS.SUM_FROM: {
						if (parseFloat(value) >= parseFloat(selectedItem.additionalValue) && parseFloat(value) < parseFloat(selectedItem.limitValue)) {
							setValidationFee(false);
						} else {
							setValidationFee(true);
						}
						break;
					}
				}
			}
		} else if (selectedItem.limitValue) {
				switch (selectedItem.key) {
					case SERVICE_DETAILS_FEE_KEYS.FEE_MAX:
					case SERVICE_DETAILS_FEE_KEYS.SUM_TO: {
						if (parseFloat(value) > parseFloat(selectedItem.limitValue)) {
							setValidationFee(false);
						} else {
							setValidationFee(true);
						}
						break;
					}
					case SERVICE_DETAILS_FEE_KEYS.FEE_MIN:
					case SERVICE_DETAILS_FEE_KEYS.SUM_FROM: {
						if (parseFloat(value) < parseFloat(selectedItem.limitValue)) {
							setValidationFee(false);
						} else {
							setValidationFee(true);
						}
						break;
					}
				}
			} else {
				setValidationFee(false);
			}
		setInputValue(value);
	};

	const handleChangeSelect = (event: SelectChangeEvent<string>) => {
		setInputValue(event.target.value);
	};

	const handleSaveClick = () => {
		handleSave(inputValue, additionalData);
	};

	const dialogContent = useMemo(() => {
		switch (selectedItem.key) {
			// NOTE: togglers must be placed here
			case SERVICE_DETAILS_PAYMENT_METHODS_KEYS.RADABANK:
			case SERVICE_DETAILS_PAYMENT_METHODS_KEYS.OTHER:
				return (
					<CustomSelect 
						value={inputValue} 
						name={selectedItem.key}
						onChange={handleChangeSelect}
						placeholder={selectedItem.title}
						options={TOGGLE_SELECT_OPTIONS}
					/>
				)
			case EDIT_MODAL_FIELDS.STATUS:
				return (
					<CustomSelect
						value={inputValue}
						name={selectedItem.key}
						onChange={handleChangeSelect}
						placeholder={selectedItem.title}
						options={USER_STATUS_OPTIONS}
					/>
				);

			case EDIT_MODAL_FIELDS.STREET_TYPE:
				return (
					<AsyncAutocomplete
						searchEndpoint={selectedItem.searchEndpoint as string}
						value={inputValue}
						setValue={setInputValue}
						setAdditionalData={setAdditionalData}
					/>
				);

			case EDIT_MODAL_FIELDS.STREET:
				return (
					<AsyncAutocomplete
						searchEndpoint={selectedItem.searchEndpoint as string}
						value={inputValue}
						setValue={setInputValue}
						setAdditionalData={setAdditionalData}
					/>
				);

			case EDIT_MODAL_FIELDS.ROLE:
				return (
					<CustomSelect
					helperText=''
						value={inputValue}
						name={selectedItem.key}
						onChange={handleChangeSelect}
						placeholder={selectedItem.title}
						options={USER_ROLE_OPTIONS}
					/>
				);

			case EDIT_MODAL_FIELDS.RIGHTS:
				return (
					<CustomSelect
						multiple
						value={inputValue}
						name={selectedItem.key}
						onChange={handleChangeSelect}
						placeholder={selectedItem.title}
						options={rightsOptions}
					/>
				);

				case EDIT_MODAL_FIELDS.ROLES:
				return (
					<CustomSelect
						multiple
						value={inputValue}
						name={selectedItem.key}
						onChange={handleChangeSelect}
						placeholder={selectedItem.title}
						options={rolesOptions}
					/>
				);

			case SERVICE_DETAILS_FEE_KEYS.FEE_MIN:
			case SERVICE_DETAILS_FEE_KEYS.FEE_MAX:
			case SERVICE_DETAILS_FEE_KEYS.SUM_FROM:
			case SERVICE_DETAILS_FEE_KEYS.SUM_TO:
			case SERVICE_DETAILS_FEE_KEYS.FEE_PERCENT:
			case SERVICE_DETAILS_FEE_KEYS.FIXED_AMOUNT:
				return (
					<TextField
						variant="outlined"
						value={inputValue}
						name={selectedItem.key}
						onChange={handleOnChangeFee}
						type="number"
						label={selectedItem.title}
					/>
				);
			default:
				return <TextField variant="outlined" label={selectedItem.title} value={inputValue} onChange={handleOnChange} />;
		}
	}, [selectedItem, inputValue, rightsOptions, rolesOptions]);

	return (
		<Dialog open={isOpen} onClose={onClose} {...props}>
			<DialogTitle>
				{title}
				<IconButton
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: '16px',
						top: '16px',
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '40px' }}>
					{dialogContent}
					{Object.values(SERVICE_DETAILS_FEE_KEYS).includes(selectedItem.key as SERVICE_DETAILS_FEE_KEYS)
						? <Button variant="rounded" onClick={handleSaveClick} disabled={validationFee}>
							Зберегти зміни
						</Button>
						: <Button variant="rounded" onClick={handleSaveClick} disabled={!inputValue || defaultValue === inputValue}>
							Зберегти зміни
						</Button>
					}
				</Box>
			</DialogContent>
		</Dialog>
	);
};
