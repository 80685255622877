import { ROUTES } from 'routes';
import { PROVIDER_TYPE, TOGGLE_VARIANTS } from 'utils/enums';

export const CREATE_ROUTES = [`/${ROUTES.users}`, `/${ROUTES.roles}`, `/${ROUTES.rights}`, `/${ROUTES.clients}`];

export const PROVIDER_NAMES = {
	HCS: 'Жилкомсервіс КП (ЄК)',
	KVD: 'Харківводоканал, КП',
	KTS: 'КП Харківські теплові мережі',
	KVBO: 'КП Комплекс з вивезення побутового сміття',
};

export const PROVIDERS_DATA = [
	{ name: PROVIDER_NAMES[PROVIDER_TYPE.HCS], value: PROVIDER_TYPE.HCS },
	{ name: PROVIDER_NAMES[PROVIDER_TYPE.KVD], value: PROVIDER_TYPE.KVD },
	{ name: PROVIDER_NAMES[PROVIDER_TYPE.KTS], value: PROVIDER_TYPE.KTS },
	{ name: PROVIDER_NAMES[PROVIDER_TYPE.KVBO], value: PROVIDER_TYPE.KVBO },
];

export const RECOVERY_TIME_MS = 30000;

export const TOGGLE_SELECT_OPTIONS = [
	{ title: 'Так', value: TOGGLE_VARIANTS.TRUE },
	{ title: 'Hi', value: TOGGLE_VARIANTS.FALSE },
];
